.tags-dropdown {
  .rc-virtual-list-holder-inner {
    flex-direction: row !important;
    flex-wrap: wrap;
    .ant-select-item {
      background: #f3f7f9;
      border-radius: 40px;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 16px 8px 20px 12px;
      height: 24px;
      > div {
        margin: 0px 12px;
      }

      .ant-select-item-option-state {
        display: none;
      }
      // span {
      //     display: none;
      // }
    }
    .ant-select-item-option-selected {
      border: solid 2px #fde255;
      display: flex;
      align-items: center;
      background-color: rgba(253, 226, 85, 0.1);
    }
  }
}
.option-dropdown {
  .rc-virtual-list-holder-inner {
    .ant-select-item-option-content {
      .anticon-plus {
        display: none;
      }
      font-family: Aeonik;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
    }
  }
}
