@import "./assets/styles/dropdown.scss";

body {
  margin: 0;
  font-family: Popins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 80vw;

  @media only screen and (min-width: 800px) {
    width: 60vw;
  }

  @media only screen and (min-width: 1200px) {
    width: 35vw;
    padding-bottom: 70%;
  }

  @media only screen and (min-width: 1400px) {
    width: 30vw;
  }
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.ant-layout-sider {
  z-index: 1;
}

.ant-tabs-ink-bar {
  display: none;
}

.ant-tabs-tab > .ant-tabs-tab-btn {
  color: #526b7c !important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #c0a725 !important;
}

.ant-tabs-tab > .ant-tabs-tab-btn:hover {
  color: #526b7c !important;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn:hover {
  color: #c0a725 !important;
}
