.btn-create_shipment:hover {
  background-color: rgba(82, 107, 124, 0.1) !important;
}

.status__popup {
  white-space: initial;
}

.body__whitesmoke {
  background-color: #f6f4f0 !important;
}

.tracking-info--red {
  background-color: #f2ebec;
}
